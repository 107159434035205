import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridRow } from "@mui/x-data-grid";
import PositiveAction from "../../../components/Button/AddAction";
import NegativeAction from "../../../components/Button/NegativeAction";

import {
  ADD_PRODUCT,
  GET_FLAT_PRODUCTS_WITH_COUNT,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

import { mapFieldsToInput, mapNullAssociatedFields } from "../utils/dataMapper";

import {
  priceFields,
  modifierFields,
  getColumns,
  statusColumns,
} from "../utils/columns";

export const getNewRow = () => {
  const id = v4();

  const newRow = {
    id,
    description: "",
    tillDescription: "",
    productCode: "",
    subGroup: "",
    productGroup: "",
    modifierOf: "",
    maxMod: null,
    vatCode: "",
    // We use this when updating a row to tell us this product needs to be created
    createdRow: true,
  };

  priceFields.forEach(price => {
    newRow[price] = null;
  });

  modifierFields.forEach(modifier => {
    newRow[modifier] = "";
  });

  statusColumns.forEach(({ field }) => {
    newRow[field] = false;
  });

  return newRow;
};

export const modifiersErrorMessage =
  "A product cannot be both a modifier and part of modifier groups";

const StyledBox = styled("div")(({ theme }) => ({
  height: "65vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const defaultOptionsArray = [];

function BulkEditorTable({
  appStore,
  // productOptions will be required down the line

  productOptions = defaultOptionsArray,
  modifierGroups = defaultOptionsArray,
  // vatRates will be a dropdown in the future

  vatRates = defaultOptionsArray,
  subGroups = defaultOptionsArray,
  productGroups = defaultOptionsArray,
}) {
  const { siteId } = useParams();

  const hasSite = siteId !== "default" && siteId !== undefined;

  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [createdRow, setCreatedRow] = useState(false);
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState([]);
  const [sortModel, setSortModel] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const columns = getColumns(modifierGroups, subGroups, productGroups, hasSite);

  useEffect(() => {
    setOffset(currentPage * limit);
    setCreatedRow(false);
  }, [currentPage, limit]);

  useEffect(() => {
    setSort(
      sortModel.map(sortItem => ({
        field: sortItem.field,
        direction: sortItem.sort.toUpperCase(),
      })),
    );
    setCreatedRow(false);
  }, [sortModel]);

  useEffect(() => {
    setLoading(true);
    setCreatedRow(false);
  }, [siteId]);

  const {
    fetchMore,
    data,
    error: getProductsError,
  } = useQuery(gql(GET_FLAT_PRODUCTS_WITH_COUNT()), {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      appStore.setLoading(false);
      setLoading(false);

      fetchMore({
        variables: {
          limit,
          offset: offset + limit,
          ...(sort.length > 0 && { sort }),
          ...(hasSite ? { siteId } : { siteId: "default" }),
        },
      });
    },
    variables: {
      limit,
      offset,
      ...(sort.length > 0 && { sort }),
      ...(hasSite ? { siteId } : { siteId: "default" }),
    },
  });

  const [addProduct] = useMutation(gql(ADD_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your new product has been created", {
        SnackbarProps: { "data-testid": "bulk-product-added-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_FLAT_PRODUCTS_WITH_COUNT()), // DocumentNode object
      "FlatProducts", // Query name
    ],
  });

  const [updateProduct] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "bulk-product-saved-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_FLAT_PRODUCTS_WITH_COUNT()), // DocumentNode object
      "FlatProducts", // Query name
    ],
  });

  useEffect(() => {
    if (data?.flatProducts?.flatProducts) {
      setTableData(
        data.flatProducts.flatProducts.map(row => mapNullAssociatedFields(row)),
      );
      setTotalRows(data.flatProducts.totalRows);
    }
  }, [data]);

  useEffect(() => {
    if (getProductsError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg = getProductsError?.message || fallbackErrorMessage;

      enqueueSnackbar(`Error: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "bulk-product-error-snackbar",
        },
      });
    }
  }, [getProductsError, enqueueSnackbar]);

  const validateModifierGroups = row => {
    let hasModifierChanges = false;

    modifierFields.forEach(modifier => {
      if (row[modifier] !== "") {
        hasModifierChanges = true;
      }
    });

    if (hasModifierChanges && row.modifierOf !== "") {
      return false;
    }

    return true;
  };

  const updateRow = (newRow, oldRow) => {
    return new Promise((resolve, reject) => {
      // Stop rows being updated where a product is part of a modifier group
      // as well as having modifier groups attached to it.
      if (!validateModifierGroups(newRow)) {
        reject(new Error(modifiersErrorMessage));
        return;
      }

      // Get any changed fields
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      if (Object.keys(rowUpdates).length) {
        // If a price level or modifier group change is included, we need to include
        // all price levels and modifier groups, as the mutation accepts an array of all values
        let hasModifierChanges = false;
        let hasPriceChanges = false;

        Object.keys(rowUpdates).forEach(key => {
          if (modifierFields.includes(key)) {
            hasModifierChanges = true;
          }

          if (priceFields.includes(key)) {
            hasPriceChanges = true;
          }
        });

        if (hasModifierChanges) {
          modifierFields.forEach(modifierField => {
            rowUpdates[modifierField] = newRow[modifierField];
          });
        }

        if (hasPriceChanges) {
          priceFields.forEach(priceField => {
            rowUpdates[priceField] = newRow[priceField];
          });
        }

        // Both sub and product group tags are currently required in the mutation, otherwise the missing one is lost
        if (
          rowUpdates.subGroup !== undefined ||
          rowUpdates.productGroup !== undefined
        ) {
          rowUpdates.subGroup = newRow.subGroup;
          rowUpdates.productGroup = newRow.productGroup;
        }

        if (newRow.createdRow !== undefined) {
          const fields = mapFieldsToInput(rowUpdates);

          if (fields.prices === undefined) {
            fields.prices = [];
          }

          addProduct({
            variables: {
              input: {
                ...fields,
              },
            },
          })
            .then(() => {
              setCreatedRow(false);
              resolve(newRow);
            })
            .catch(error => {
              // The API returns an error, so we send this to the onProcessRowUpdateError handler
              // this stops the row from going off edit mode, showing the user there's a problem
              const errorMsg = error?.message || "Please try again later.";
              reject(new Error(errorMsg));
            });
        } else {
          updateProduct({
            variables: {
              input: {
                id: newRow.id,
                ...(hasSite && { siteId }),
                ...mapFieldsToInput(rowUpdates),
              },
            },
          })
            .then(() => {
              resolve(newRow);
            })
            .catch(error => {
              const errorMsg = error?.message || "Please try again later.";
              reject(new Error(errorMsg));
            });
        }
      } else {
        // No updates to be made, resolve with row as exists
        resolve(newRow);
      }
    });
  };

  const EditToolbar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={{ my: 1, mr: 1, marginLeft: "auto" }}>
          <Box sx={{ mr: 1, display: "inline" }}>
            <NegativeAction
              buttonText="Delete Product"
              disabled={!createdRow}
              onClick={() => {
                const newTableData = tableData.filter(
                  row => row.createdRow === undefined,
                );
                setTableData(newTableData);
                setCreatedRow(false);
              }}
              testId="delete-product-button"
            />
          </Box>
          <PositiveAction
            buttonText="Add Product"
            disabled={createdRow || hasSite}
            onClick={() => {
              const newRow = getNewRow();

              const newTableData = [...tableData];
              newTableData.unshift(newRow);

              setTableData(newTableData);
              setCreatedRow(true);
            }}
            testId="add-product-button"
          />
        </Box>
      </GridToolbarContainer>
    );
  };

  const CustomGridRow = props => {
    // eslint-disable-next-line react/prop-types
    const { index } = props;
    return <GridRow {...props} data-testid={`row-${index}`} />;
  };

  return (
    <StyledBox>
      <DataGrid
        editMode="row"
        columns={columns}
        loading={loading}
        rows={tableData}
        rowCount={totalRows}
        rowHeight={35}
        onPaginationModelChange={({ page, pageSize }) => {
          setCurrentPage(page);
          setLimit(pageSize);
        }}
        onProcessRowUpdateError={errorMsg =>
          enqueueSnackbar(`${errorMsg}`, {
            variant: "error",
            SnackbarProps: {
              "data-testid": "bulk-product-update-error-snackbar",
            },
          })
        }
        processRowUpdate={updateRow}
        paginationMode="server"
        paginationModel={{ page: currentPage, pageSize: limit }}
        slots={{
          toolbar: EditToolbar,
          row: CustomGridRow,
        }}
        sortingMode="server"
        onSortModelChange={setSortModel}
      />
    </StyledBox>
  );
}

const dropdownListValidation = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
);

BulkEditorTable.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  productOptions: dropdownListValidation,
  modifierGroups: dropdownListValidation,
  vatRates: dropdownListValidation,
  subGroups: dropdownListValidation,
  productGroups: dropdownListValidation,
};

export default inject("appStore")(observer(BulkEditorTable));
