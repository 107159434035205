import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";

import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment PromotionFullDetails on Promotion {
      id
      name
      active
      description
      priority
      startAt
      endAt
      rules
      actions
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

export default types
  .model("Promotion", {
    id: types.identifier,
    name: types.string,
    active: types.boolean,
    description: types.maybeNull(types.string),
    priority: types.maybeNull(types.integer),
    startAt: DateTime,
    endAt: types.maybeNull(DateTime),
    rules: types.maybeNull(types.string),
    actions: types.maybeNull(types.string),
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get promotionStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.promotionStore);
    },
  }))
  .actions(self => {
    return {
      setFilter(isFiltered) {
        self.isFiltered = isFiltered;
      },

      setName(value) {
        self.name = value;
        return self;
      },

      setActive(value) {
        self.active = value;
        return self;
      },

      setDescription(value) {
        self.description = value;
        return self;
      },

      setPriority(value) {
        self.priority = value;
        return self;
      },

      setStartAt(value) {
        self.startAt = value;
        return self;
      },

      setEndAt(value) {
        self.endAt = value;
        return self;
      },

      setRules(value) {
        self.rules = value;
        return self;
      },

      setActions(value) {
        self.actions = value;
        return self;
      },

      getActionSummary() {
        const actions = JSON.parse(self.actions);
        const key = Object.keys(actions)[0];
        switch (key) {
          case "fixed_price":
            return `Fixed price £${actions.fixed_price}`;
          case "multibuy":
            return `Multibuy ${actions[key].qualifyingCount} for ${actions[key].fullPriceCount} - ${actions[key].mode} free`;
          case "free_products":
            return `Free product${
              actions[key].length <= 1 ? "" : "s"
            }: ${actions[key].map(p => p.name).join(", ")}`;
          default:
            return key.replaceAll("_", " ");
        }
      },

      save: flow(function* save(changesetId = null) {
        delete self.isFiltered;

        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          active: self.active,
          name: self.name,
          description: self.description,
          startAt: self.startAt,
          endAt: self.endAt,
          rules: self.rules,
          actions: self.actions,
          ...(changesetId && { changesetId }),
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation update {
            updatePromotion(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
