import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import validator from "validator";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import SwitchComponent from "./Switch";
import DropdownComponent from "./Dropdown";
import TextInputComponent from "./TextInput";
import PositiveAction from "../../../components/Button/PositiveAction";

const TabPanel = ({ appStore, settings, isSaved }) => {
  const setStartValues = currentSettings => {
    const startValues = {};

    currentSettings.items.forEach(setting => {
      if (setting.type === "DROPDOWN") {
        startValues[setting.storeName] =
          appStore.settingsStore[setting.storeName].id;
      } else {
        startValues[setting.storeName] =
          appStore.settingsStore[setting.storeName];
      }
    });

    return startValues;
  };

  const [fieldValues, setFieldValues] = useState(setStartValues(settings));
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const newFields = setStartValues(settings);
    setFieldValues(newFields);
  }, [settings.category]);

  const updateField = (field, value) => {
    const newFields = fieldValues;
    newFields[field] = value;

    setFieldValues({
      ...newFields,
    });

    isSaved(false);
  };

  const saveSettings = () => {
    appStore.setLoading();

    return new Promise((resolve, reject) => {
      appStore.settingsStore
        .save()
        .then(() => {
          appStore.setLoading(false);
          isSaved(true);
          resolve(true);
        })
        .catch(error => {
          appStore.log.error(error);
          appStore.setLoading(false);
          reject(error);
        });
    });
  };

  const handleSubmit = () => {
    let hasErrors = false;

    setFieldErrors({});

    // For any setting values that have changed, update the relevant appStore state then save to DB.
    settings.items.forEach(setting => {
      const fieldName = setting.storeName;
      const newValue = fieldValues[fieldName];
      let fieldError = "";

      if (newValue !== appStore.settingsStore[fieldName]) {
        // Validation rules
        if (setting.required && validator.isEmpty(newValue.toString())) {
          fieldError = setting.errorMessage;
        } else if (
          setting.type === "NUMBER" &&
          !validator.isInt(newValue.toString())
        ) {
          fieldError = setting.errorMessage;
        } else if (
          setting.validationRegex &&
          !validator.matches(newValue.toString(), setting.validationRegex)
        ) {
          fieldError = setting.errorMessage;
        } else {
          setting.storeSetter(newValue);
        }

        if (fieldError) {
          setFieldErrors({
            ...fieldErrors,
            [fieldName]: fieldError,
          });

          hasErrors = true;
        }
      }
    });

    if (!hasErrors) {
      saveSettings();
    }
  };

  return (
    <Card data-testid={`settings-panel-${settings.category}`}>
      <CardContent>
        {fieldValues && (
          <Table sx={{ display: "inline" }}>
            <TableBody>
              {settings.items.map(setting => {
                switch (setting.type) {
                  case "SWITCH":
                    return (
                      <SwitchComponent
                        key={`setting-${setting.storeName}`}
                        name={setting.storeName}
                        label={setting.label}
                        value={
                          fieldValues[setting.storeName]
                            ? fieldValues[setting.storeName]
                            : false
                        }
                        updateCallback={updateField}
                        testId={`settings-field-${setting.storeName}`}
                      />
                    );
                  case "DROPDOWN":
                    return (
                      <DropdownComponent
                        key={`setting-${setting.storeName}`}
                        name={setting.storeName}
                        label={setting.label}
                        options={setting.storeOptions}
                        value={
                          fieldValues[setting.storeName]
                            ? fieldValues[setting.storeName]
                            : ""
                        }
                        updateCallback={updateField}
                        testId={`settings-field-${setting.storeName}`}
                      />
                    );
                  case "CUSTOM":
                    return setting.customRender;
                  default:
                    return (
                      <TextInputComponent
                        key={`setting-${setting.storeName}`}
                        error={
                          fieldErrors[setting.storeName] &&
                          fieldErrors[setting.storeName]
                        }
                        name={setting.storeName}
                        label={setting.label}
                        value={
                          fieldValues[setting.storeName]
                            ? fieldValues[setting.storeName]
                            : ""
                        }
                        updateCallback={updateField}
                        testId={`settings-field-${setting.storeName}`}
                      />
                    );
                }
              })}
            </TableBody>
          </Table>
        )}
        {!settings.disableDefaultSave && (
          <Box mt={1} py={2}>
            <PositiveAction
              buttonText="Save"
              onClick={handleSubmit}
              testId={`settings-${settings.category}-save-btn`}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

TabPanel.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  settings: PropTypes.shape([
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      items: PropTypes.shape([
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          storeName: PropTypes.string.isRequired,
          storeSetter: PropTypes.func.isRequired,
          storeOptions: PropTypes.string,
          type: PropTypes.string.isRequired,
          required: PropTypes.bool,
          errorMessage: PropTypes.function,
        }),
      ]),
    }),
  ]).isRequired,
  isSaved: PropTypes.func.isRequired,
};

const Component = inject("appStore")(observer(TabPanel));

export default Component;
