import React from "react";
import PropTypes from "prop-types";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const TableRowComponent = ({ name, children }) => {
  return (
    <TableRow>
      <TableCell>{name}:</TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  );
};

TableRowComponent.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TableRowComponent;
