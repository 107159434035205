import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import { transformer } from "../helpers";

const BookingCancellationPolicy = types.model("BookingCancellationPolicy", {
  id: types.identifier,
  name: types.string,
});

const LoyaltyProfile = types.model("LoyaltyProfile", {
  id: types.string,
  name: types.string,
  discountId: types.maybeNull(types.string),
});

// @todo treat versioning changes on preProcess
export default types
  .model("SettingsStore", {
    accountName: types.maybeNull(types.string),
    bookingCancellationPolicies: types.optional(
      types.map(BookingCancellationPolicy),
      {
        ALLOW: {
          id: "ALLOW",
          name: "Allow anywhere",
        },
        TILL_ONLY: {
          id: "TILL_ONLY",
          name: "Till only",
        },
        FORFEIT_DEPOSIT: {
          id: "FORFEIT_DEPOSIT",
          name: "Forfeit deposit",
        },
      },
    ),
    bookingCancellationPolicy: types.maybeNull(
      types.reference(BookingCancellationPolicy),
    ),
    minimumOrderSurchargePlu: types.maybeNull(types.string),
    deliveryFeePlu: types.maybeNull(types.string),
    deliverooDiscountId: types.maybeNull(types.string),
    vitaMojoDiscountId: types.maybeNull(types.string),
    polarisDataSubdomain: types.maybeNull(types.string),
    POSServiceChargeId: types.maybeNull(types.string),
    POSVersion: types.maybeNull(types.string),
    loyaltyUrl: types.maybeNull(types.string),
    loyaltyUsername: types.maybeNull(types.string),
    loyaltyPassword: types.maybeNull(types.string),
    loyaltyProfiles: types.array(LoyaltyProfile),
    isCollinsPreorderEnabled: types.optional(types.boolean, false),
    isTableNumberEnabled: types.optional(types.boolean, false),
    isEatInEnabled: types.optional(types.boolean, false),
    isSyncEnabled: types.optional(types.boolean, false),
    isFluxEnabled: types.optional(types.boolean, false),
    isReportTransactionCountEnabled: types.optional(types.boolean, false),
    uncompileOrderItemsForPOS: types.optional(types.boolean, false),
    showChangesets: types.optional(types.boolean, false),
    // Maybe null because we sometimes instantiate this store without most of these values, obviously.
    justEatCredentials: types.maybeNull(
      types
        .model({
          // Could be null from the API, so default to ""
          apiKey: types.optional(types.string, ""),
          hmacKey: types.optional(types.string, ""),
          xFlytApiKey: types.optional(types.string, ""),
        })
        .actions(justEatModel => ({
          setApiKey(value) {
            justEatModel.apiKey = value ?? "";
          },
          setHmacKey(value) {
            justEatModel.hmacKey = value ?? "";
          },
          setXFlytApiKey(value) {
            justEatModel.xFlytApiKey = value ?? "";
          },
        })),
    ),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))

  .actions(self => ({
    setAccountName(value) {
      self.accountName = value;
      return self;
    },

    setBookingCancellationPolicy(value) {
      self.bookingCancellationPolicy = value;
      return self;
    },

    setMinimumOrderSurchargePlu(value) {
      self.minimumOrderSurchargePlu = value;
      return self;
    },

    setDeliveryFeePlu(value) {
      self.deliveryFeePlu = value;
      return self;
    },

    setDeliverooDiscountId(value) {
      self.deliverooDiscountId = value;
      return self;
    },

    setVitaMojoDiscountId(value) {
      self.vitaMojoDiscountId = value;
      return self;
    },

    setPolarisDataSubdomain(value) {
      self.polarisDataSubdomain = value ? value : null;
      return self;
    },

    setPOSServiceChargeId(value) {
      self.POSServiceChargeId = value;
      return self;
    },

    setPOSVersion(value) {
      self.POSVersion = value;
      return self;
    },

    setLoyaltyUrl(value) {
      self.loyaltyUrl = value;
      return self;
    },

    setLoyaltyUsername(value) {
      self.loyaltyUsername = value;
      return self;
    },

    setLoyaltyPassword(value) {
      self.loyaltyPassword = value;
      return self;
    },

    setLoyaltyProfiles(value) {
      self.loyaltyProfiles = value;
      return self;
    },

    setIsCollinsPreorderEnabled(value) {
      self.isCollinsPreorderEnabled = value;
      return self;
    },

    setIsTableNumberEnabled(value) {
      self.isTableNumberEnabled = value;
      return self;
    },

    setIsEatInEnabled(value) {
      self.isEatInEnabled = value;
      return self;
    },

    setIsSyncEnabled(value) {
      self.isSyncEnabled = value;
      return self;
    },

    setIsFluxEnabled(value) {
      self.isFluxEnabled = value;
      return self;
    },

    setIsReportTransactionCountEnabled(value) {
      self.isReportTransactionCountEnabled = value;
      return self;
    },

    setUncompileOrderItemsForPOS(value) {
      self.uncompileOrderItemsForPOS = value;
      return self;
    },

    setShowChangesets(value) {
      self.showChangesets = value;
      return self;
    },

    setJustEatCredentials(value) {
      self.justEatCredentials = {
        apiKey: value.apiKey ?? "",
        hmacKey: value.hmacKey ?? "",
        xFlytApiKey: value.xFlytApiKey ?? "",
      };
      return self;
    },

    setJustEatApiKey(value) {
      self.justEatCredentials.setApiKey(value);
      return self;
    },

    setJustEatHmacKey(value) {
      self.justEatCredentials.setHmacKey(value);
      return self;
    },

    setJustEatXFlytApiKey(value) {
      self.justEatCredentials.setXFlytApiKey(value);
      return self;
    },

    syncPOSVersions: flow(function* syncPOSVersions() {
      const { apolloClient } = getEnv(self);

      yield apolloClient.mutate({
        mutation: gql`
          mutation syncPOSVersions {
            syncPOSVersions
          }
        `,
      });
    }),

    testLoyaltyConnection: flow(function* testLoyaltyConnection({
      loyaltyUrl,
      loyaltyUsername,
      loyaltyPassword,
      siteId,
    }) {
      const { apolloClient } = getEnv(self);
      const params = transformer({
        loyaltyUrl,
        loyaltyUsername,
        loyaltyPassword,
        siteId: siteId ?? null,
      });
      try {
        const response = yield apolloClient.mutate({
          mutation: gql`
          mutation testLoyaltyConnection {
            testLoyaltyConnection(input: {${params}}) {
              success
              message
            }
          }
        `,
        });

        return Promise.resolve(response.data.testLoyaltyConnection);
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    load: flow(function* load(useCache = true) {
      // `apolloClient` is not in scope as this gets called on a clone

      const { apolloClient } = getEnv(self);

      const response = yield apolloClient.query({
        ...(!useCache ? { fetchPolicy: "no-cache" } : {}),
        query: gql`
          {
            settings {
              accountName
              bookingCancellationPolicy
              minimumOrderSurchargePlu
              deliveryFeePlu
              deliverooDiscountId
              vitaMojoDiscountId
              polarisDataSubdomain
              POSServiceChargeId
              POSVersion
              loyaltyUrl
              loyaltyUsername
              loyaltyPassword
              loyaltyProfiles {
                id
                name
                discountId
              }
              isCollinsPreorderEnabled
              isTableNumberEnabled
              isEatInEnabled
              isSyncEnabled
              isFluxEnabled
              isReportTransactionCountEnabled
              uncompileOrderItemsForPOS
              showChangesets
              justEatCredentials {
                apiKey
                hmacKey
                xFlytApiKey
              }
            }
          }
        `,
      });

      const {
        accountName,
        bookingCancellationPolicy,
        minimumOrderSurchargePlu,
        deliveryFeePlu,
        deliverooDiscountId,
        vitaMojoDiscountId,
        polarisDataSubdomain,
        POSServiceChargeId,
        POSVersion,
        loyaltyUrl,
        loyaltyUsername,
        loyaltyPassword,
        loyaltyProfiles,
        isCollinsPreorderEnabled,
        isTableNumberEnabled,
        isEatInEnabled,
        isSyncEnabled,
        isFluxEnabled,
        isReportTransactionCountEnabled,
        justEatCredentials,
        uncompileOrderItemsForPOS,
        showChangesets,
      } = response.data.settings;

      self.setAccountName(accountName);
      self.setBookingCancellationPolicy(bookingCancellationPolicy);
      self.setMinimumOrderSurchargePlu(minimumOrderSurchargePlu);
      self.setDeliveryFeePlu(deliveryFeePlu);
      self.setDeliverooDiscountId(deliverooDiscountId);
      self.setVitaMojoDiscountId(vitaMojoDiscountId);
      self.setPolarisDataSubdomain(polarisDataSubdomain);
      self.setPOSServiceChargeId(POSServiceChargeId);
      self.setPOSVersion(POSVersion);
      self.setLoyaltyUrl(loyaltyUrl);
      self.setLoyaltyUsername(loyaltyUsername);
      self.setLoyaltyPassword(loyaltyPassword);
      self.setLoyaltyProfiles(loyaltyProfiles);
      self.setIsCollinsPreorderEnabled(isCollinsPreorderEnabled);
      self.setIsTableNumberEnabled(isTableNumberEnabled);
      self.setIsEatInEnabled(isEatInEnabled);
      self.setIsSyncEnabled(isSyncEnabled);
      self.setIsFluxEnabled(isFluxEnabled);
      self.setIsReportTransactionCountEnabled(isReportTransactionCountEnabled);
      self.setUncompileOrderItemsForPOS(uncompileOrderItemsForPOS);
      self.setShowChangesets(showChangesets);
      self.setJustEatCredentials(justEatCredentials);
    }),

    // The primary use for this is to keep the session alive in "logoutActivityMonitor.js"
    loadNoCache: flow(function* loadNoCache() {
      const { apolloClient } = getEnv(self);

      const response = yield apolloClient.query({
        fetchPolicy: "no-cache",
        query: gql`
          {
            settings {
              accountName
            }
          }
        `,
      });

      return Promise.resolve(response.data.settings);
    }),

    save: flow(function* save() {
      const { apolloClient } = getEnv(self);

      const params = transformer({
        accountName: self.accountName,
        bookingCancellationPolicy: () => self.bookingCancellationPolicy.id,
        minimumOrderSurchargePlu: self.minimumOrderSurchargePlu,
        deliveryFeePlu: self.deliveryFeePlu,
        deliverooDiscountId: self.deliverooDiscountId,
        vitaMojoDiscountId: self.vitaMojoDiscountId,
        polarisDataSubdomain: self.polarisDataSubdomain,
        POSServiceChargeId: self.POSServiceChargeId,
        POSVersion: self.POSVersion,
        loyaltyUrl: self.loyaltyUrl,
        loyaltyUsername: self.loyaltyUsername,
        loyaltyPassword: self.loyaltyPassword,
        isCollinsPreorderEnabled: self.isCollinsPreorderEnabled,
        isTableNumberEnabled: self.isTableNumberEnabled,
        isEatInEnabled: self.isEatInEnabled,
        isSyncEnabled: self.isSyncEnabled,
        isFluxEnabled: self.isFluxEnabled,
        isReportTransactionCountEnabled: self.isReportTransactionCountEnabled,
        uncompileOrderItemsForPOS: self.uncompileOrderItemsForPOS,
        showChangesets: self.showChangesets,
      });

      yield apolloClient.mutate({
        mutation: gql`
          mutation updateSettings {
            updateSettings(input: {
                ${params}
              }) {
                accountName
                bookingCancellationPolicy
                minimumOrderSurchargePlu
                deliveryFeePlu
                deliverooDiscountId
                vitaMojoDiscountId
                polarisDataSubdomain
                POSServiceChargeId
                POSVersion
                loyaltyUrl
                loyaltyUsername
                loyaltyPassword
                isCollinsPreorderEnabled
                isTableNumberEnabled
                isEatInEnabled
                isSyncEnabled
                isFluxEnabled
                isReportTransactionCountEnabled
                uncompileOrderItemsForPOS
                showChangesets
              }
          }
      `,
      });
    }),
  }));
