import { hasNumberValidationError } from "../../../components/Datagrid/utils/validation";

export const generateTableColumn = ({
  headerName,
  field,
  type,
  dropdownOptions,
  onlyInteger,
  width = 100,
  sortable = false,
  required = false,
  editable = true,
}) => {
  const defaultProps = {
    headerName,
    field,
    type,
    width,
    sortable,
    editable,
  };

  switch (type) {
    case "text":
      if (required) {
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return { ...props, error: !value.length > 0 };
          },
        };
      }

      if (field === "productCode") {
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return { ...props, error: value.length < 5 };
          },
        };
      }

      return defaultProps;
    case "boolean":
      return defaultProps;
    case "number":
      return {
        ...defaultProps,
        preProcessEditCellProps: params => {
          const { props } = params;
          const { value } = props;

          return {
            ...props,
            error: hasNumberValidationError(value, onlyInteger),
          };
        },
      };
    case "singleSelect":
      return {
        ...defaultProps,
        getOptionLabel: ({ text }) => text,
        getOptionValue: ({ value }) => value,
        valueOptions: [{ text: "-", value: "" }, ...dropdownOptions],
      };
    default:
      throw new Error("Bad column config.");
  }
};

export const priceFields = Array.from(Array(6)).map(
  (item, index) => `price${index + 1}`,
);

export const modifierFields = Array.from(Array(6)).map(
  (item, index) => `modifierGroup${index + 1}`,
);

export const statusColumns = [
  { headerName: "Negative Price", field: "negative_price", width: 120 },
  { headerName: "Red Print", field: "red_print" },
  { headerName: "Open Description", field: "open_description", width: 150 },
  { headerName: "Open Price", field: "open_price" },
  { headerName: "Modifier", field: "modifier" },
  {
    headerName: "Promotional Trigger",
    field: "promotional_trigger",
    width: 170,
  },
  { headerName: "Receipt Suppress", field: "receipt_suppress", width: 150 },
].map(({ headerName, field, width = 100 }) => ({
  headerName,
  field,
  type: "boolean",
  width,
}));

const siteEnabledColumn = {
  headerName: "Picked",
  field: "picked",
  type: "boolean",
};

export const getColumns = (
  modifierGroups,
  subGroups,
  productGroups,
  isAtSite,
) => {
  const priceColumns = priceFields.map((item, index) => ({
    headerName: `Price ${index + 1}`,
    field: item,
    type: "number",
  }));

  const modifierColumns = modifierFields.map((item, index) => ({
    headerName: `Modifier ${index + 1}`,
    field: item,
    type: "singleSelect",
    dropdownOptions: modifierGroups,
  }));

  return [
    {
      headerName: "Name",
      field: "description",
      type: "text",
      width: 120,
      sortable: true,
      required: true,
    },
    {
      headerName: "Till Description",
      field: "tillDescription",
      type: "text",
      width: 120,
      sortable: true,
      required: true,
    },
    {
      headerName: "Product Code",
      field: "productCode",
      type: "text",
      width: 120,
      sortable: true,
      editable: !isAtSite,
    },
    // Commenting this out until it's ready to be pulled from the API
    // {
    //   headerName: "Product Option",
    //   field: "productOption",
    //   type: "singleSelect",
    //   dropdownOptions: productOptions,
    //   width: 120,
    // },
    {
      headerName: "Sub Group",
      field: "subGroup",
      type: "singleSelect",
      dropdownOptions: subGroups,
    },
    {
      headerName: "Product Group",
      field: "productGroup",
      type: "singleSelect",
      dropdownOptions: productGroups,
      width: 120,
    },
    {
      headerName: "Modifier Of",
      field: "modifierOf",
      type: "singleSelect",
      dropdownOptions: modifierGroups,
    },
    ...priceColumns,
    ...modifierColumns,
    {
      headerName: "Max Mod",
      field: "maxMod",
      type: "number",
      onlyInteger: true,
      sortable: true,
    },
    // Commenting out as currently not available on the API
    // {
    //   headerName: "Priority",
    //   field: "priority",
    //   type: "number",
    //   onlyInteger: true,
    //   sortable: true,
    // },
    // {
    //   headerName: "RRP",
    //   field: "rrp",
    //   type: "number",
    //   sortable: true,
    // },
    // {
    //   headerName: "Cost Price",
    //   field: "costPrice",
    //   type: "number",
    //   sortable: true,
    // },
    {
      headerName: "VAT Rate",
      field: "vatCode",
      type: "text",
      // In the future this will be a dropdown, currently API accepts string
      // type: "singleSelect",
      // dropdownOptions: vatRates
      sortable: true,
      editable: !isAtSite,
    },
    // Commenting out as currently not available on the API
    // {
    //   headerName: "Price Per",
    //   field: "pricePer",
    //   type: "number",
    // },
    ...statusColumns,
    ...(isAtSite ? [siteEnabledColumn] : []),
  ].map(generateTableColumn);
};
