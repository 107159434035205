import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";

import { DataGridPro, useGridApiRef, GridRow } from "@mui/x-data-grid-pro";

import { GET_DETAILED_ORDERS } from "../../../helpers/apollo/utils.js";

import { orderColumns } from "../utils/columns";
import {
  dataMapper,
  filterColumns,
  filterMapper,
  getColumnForNewFilter,
} from "../utils/helpers";
import OrdersDetailPanel from "./OrdersDetailPanel";
import CustomToolbar from "./CustomToolbar";

const OrdersBulkEditor = ({ appStore }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    itemCode: false,
    itemModifierCode: false,
  });
  const [tableData, setTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState([{ field: "createdAt", direction: "DESC" }]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [filter, setFilter] = useState({});

  const resizeColumns = () => {
    apiRef?.current?.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  };

  const { fetchMore, loading } = useQuery(gql(GET_DETAILED_ORDERS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      appStore.setLoading(false);

      if (data?.detailedOrdersWithCount?.detailedOrders) {
        setTableData(
          data.detailedOrdersWithCount.detailedOrders.map(dataMapper),
        );
        setTotalRows(data.detailedOrdersWithCount.totalRows);
      }

      fetchMore({
        variables: {
          limit,
          offset: offset + limit,
          ...(sort.length > 0 && { sort }),
          ...(filter && { filter }),
        },
      });
    },
    onError: error => {
      appStore.setLoading(false);
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "get-detailed-orders-snackbar",
        },
      });
    },
    variables: {
      limit,
      offset: offset,
      ...(sort.length > 0 && { sort }),
      ...(filter && { filter }),
    },
  });

  useEffect(() => {
    // Look at this in Tills/Components/TillsBulkEditor.js to reference of using a timeout
    setTimeout(() => {
      resizeColumns();
    }, 10);
  }, [tableData]);

  // This will hide itemCode and itemModifierCode from the column selection menu,
  // as we only want them usable as filters, but not visible in the grid
  const getTogglableColumns = columns => {
    return columns
      .filter(
        column => !["itemCode", "itemModifierCode"].includes(column.field),
      )
      .map(column => column.field);
  };

  return (
    <DataGridPro
      apiRef={apiRef}
      columns={orderColumns}
      disableRowSelectionOnClick
      pagination
      sortingMode="server"
      filterDebounceMs={300}
      filterMode="server"
      paginationMode="server"
      paginationModel={{ page: page, pageSize: limit }}
      onPaginationModelChange={({ page: newPage, pageSize }) => {
        setPage(newPage);
        setLimit(pageSize);
        setOffset(newPage * pageSize);
      }}
      onSortModelChange={sortModel => {
        setSort(
          sortModel.map(sortItem => ({
            field: sortItem.field,
            direction: sortItem.sort.toUpperCase(),
          })),
        );
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={newModel =>
        setColumnVisibilityModel(newModel)
      }
      onFilterModelChange={newFilterModel => {
        setFilter(filterMapper(newFilterModel));
      }}
      rows={tableData}
      rowCount={totalRows}
      getDetailPanelHeight={() => "auto"}
      getDetailPanelContent={({ row }) => <OrdersDetailPanel row={row} />}
      initialState={{
        density: "compact",
        sorting: {
          sortModel: [{ field: "createdAt", sort: "desc" }],
        },
      }}
      loading={loading}
      slots={{
        row: (props = {}) => {
          return <GridRow {...props} data-testid={`row-${props.index}`} />;
        },
        toolbar: () => <CustomToolbar />,
      }}
      slotProps={{
        columnsManagement: {
          getTogglableColumns,
        },
        filterPanel: {
          logicOperators: ["and"],
          filterFormProps: {
            filterColumns,
            valueInputProps: {
              sx: { minWidth: "190px", width: "auto" },
            },
          },
          getColumnForNewFilter,
        },
      }}
      sx={{
        height: "75vh",
        width: "100%",
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "transparent",
        },
        "& .status-cell-positive": {
          backgroundColor: "rgba(153,204,51,0.50)",
        },
        "& .status-cell-neutral": {
          backgroundColor: "#ffefad",
        },
        "& .status-cell-negative": {
          backgroundColor: "#ffb1b1",
        },
      }}
    />
  );
};

OrdersBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  index: PropTypes.number,
};

export default inject("appStore")(observer(OrdersBulkEditor));
